import { has } from 'lodash';

import { MemberURLParams } from '@domain/districts';

export const flattenObject = (
  obj: Object,
  // eslint-disable-next-line default-param-last
  result: MemberURLParams = {},
  parent?: string
) => {
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in obj) {
    const propName = parent ? `${parent}.${key}` : key;
    if (typeof obj[key as keyof typeof obj] === 'object') {
      flattenObject(obj[key as keyof typeof obj], result, propName);
    } else {
      // eslint-disable-next-line no-param-reassign
      (result as Record<string, Object>)[propName] =
        obj[key as keyof typeof obj];
    }
  }
  return result;
};

/** Unflatten back to a nested Object */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const unflattenObject = (flattenedObj?: Record<string, any>) => {
  if (!flattenedObj || typeof flattenedObj !== 'object') return {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: Record<string, any> = {};

  Object.keys(flattenedObj).forEach(key => {
    if (!has(flattenedObj, key)) return;

    const keys = key.split('.').filter(Boolean);

    keys.reduce((acc, part, index) => {
      const isLast = index === keys.length - 1;
      const nextPart = keys[index + 1];
      const isArray = nextPart !== undefined && !Number.isNaN(Number(nextPart));

      if (!(part in acc)) {
        // eslint-disable-next-line no-param-reassign, no-nested-ternary
        acc[part] = isLast ? flattenedObj[key] : isArray ? [] : {};
      }

      return acc[part];
    }, result);
  });

  return result;
};
